import { notification, PrivateRoute } from '@digital/ui';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { onAuth, onRefreshToken } from '../api/auth';
import storage from '../helpers/storage';
import { useActions, useStore } from '../store/models';
import Login from '../views/LoginView';
import WorkspaceRoutes from './WorkspaceRoutes';

export default () => {
  const { refreshLogin, userToken } = useStore(store => store.app);
  const isUserAuthenticated = useStore(store => store.permission.isUserAuthenticated);
  const { userAuthenticated, removeAuthenticated, loadPermissions } = useActions(actions => actions.permission);
  const { onRefreshLogin, onChangePassword, onEstablishmentId, onUserCode, onUserData, onRepresentativeId, onUserToken } = useActions(actions => actions.app);

  useEffect(() => {
    if (isUserAuthenticated && !refreshLogin) {
      onAuth().then(auth => {
        if (auth && localStorage.getItem('token-sales_portal')) {
          localStorage.setItem('token-sales_portal', localStorage.getItem('token-sales_portal') || '');
          onRefreshLogin(true);
          if (!auth.user.PasswordUpdatedAt) {
            onChangePassword(true);
          }
          const userAuth = {
            username: auth.user.Name,
            accessToken: storage.getApiToken() || '',
          }
          onUserToken(storage.getApiToken() || '');
          onEstablishmentId(auth.user.EstablishmentId);
          onRepresentativeId(auth.user.RepresentativeId);
          onUserCode(auth.user.UserCode);
          onUserData({
            Name: auth.user.Name,
            Email: auth.user.Email,
          })
          loadPermissions([auth.permission]);
          sessionStorage.setItem('app.sales-portal', JSON.stringify({
            establishmentId: auth.user.EstablishmentId,
            token: storage.getApiToken() || ''
          }))
          userAuthenticated(userAuth);
        } else {
          notification.destroy();
          onRefreshToken().then(respRefresh => {
            if (respRefresh) {
              localStorage.setItem('token-sales_portal', respRefresh.tokenAccess);
              onRefreshLogin(true);
              const userAuth = {
                username: respRefresh.user.Name,
                accessToken: respRefresh.tokenAccess,
              }
              onUserToken(respRefresh.tokenAccess);
              onEstablishmentId(respRefresh.user.EstablishmentId);
              onRepresentativeId(respRefresh.user.RepresentativeId);
              onUserCode(respRefresh.user.UserCode);
              onUserData({
                Name: respRefresh.user.Name,
                Email: respRefresh.user.Email,
              })
              loadPermissions([respRefresh.permission]);
              userAuthenticated(userAuth);
              sessionStorage.setItem('app.sales-portal', JSON.stringify({
                establishmentId: respRefresh.user.EstablishmentId,
                token: respRefresh.tokenAccess
              }))
              setTimeout(() => window.location.reload(), 150);
            } else {
              notification.destroy();
              removeAuthenticated;
              storage.removeApiToken();
            }
          });
        }
      });
    } else {
      onRefreshLogin(true);
    }
  }, [isUserAuthenticated, refreshLogin]);

  useEffect(() => {
    if (userToken) {
      localStorage.setItem('ui.store.apiToken', userToken);
    } else {
      if (sessionStorage.getItem('app.sales-portal')) {
        const session = JSON.parse(sessionStorage.getItem('app.sales-portal') || '');
        localStorage.setItem('ui.store.apiToken', session.token);
      }
    }
  }, [localStorage.getItem('ui.store.apiToken'), userToken]);

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <PrivateRoute path="/" component={WorkspaceRoutes} isAuthenticated={isUserAuthenticated} redirectTo="/login" />
    </Switch>
  );
};
