import { Form, FormatText, FormInput, FormSubmitButton, notification } from '@digital/ui';
import { Icon, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { onPassChange, PassChangeProps } from '../../api/user';
import { useActions } from '../../store/models';

interface ChangePassProps {
  isVisible: boolean;
}

export const ChangePass = ({ isVisible }: ChangePassProps) => {
  const { t } = useTranslation();
  const onChangePassword = useActions(action => action.app.onChangePassword);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const antIcon = <Icon type="loading" style={{ fontSize: 18, color: '#FFFFFF' }} spin />;

  const schema = Yup.object({
    password: Yup.string()
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/, 'Deve conter no mínimo 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial')
      .required('Insira uma senha'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Senhas não conferem')
      .when('password', (password: string, field) =>
        password ? field.required('Confirme a senha') : field,
      ),
  });

  const handleChangePass = (values: PassChangeProps) => {
    onPassChange(values).then(() => {
      notification.success('Senha alterada com sucesso!');
      setLoadingLogin(false);
      onChangePassword(false);
    });
  };

  return (
    <Modal
      title={t('app.changePass.title')}
      width={350}
      footer={[]}
      onOk={() => onChangePassword(false)}
      visible={isVisible}
      cancelButtonProps={{ style: { display: 'none' } }}
      centered
      closable={false}>
      <Form validationSchema={schema} onSubmit={handleChangePass}>
        <FormInput label="app.userCrud.password" type="password" name="password" placeholder="app.userCrud.password" />
        <FormInput
          label="app.userCrud.confirmPassword"
          type="password"
          name="confirmPassword"
          placeholder="app.userCrud.confirmPassword"
        />
        <FormSubmitButton
          noIcon
          type="primary"
          htmlType="submit"
          style={{
            width: '100px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            marginTop: 15,
          }}>
          {loadingLogin ? antIcon : <FormatText id="app.changePass.send" />}
        </FormSubmitButton>
      </Form>
    </Modal>
  );
};
