import { addMenuTopAreaItem, Workspace } from '@digital/ui';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ChangePass } from '../components/ChangePass';
import menuData from '../menu.json';
import { useStore } from '../store/models';

// Administrative Routes
const UserView = lazy(() => import('../views/Administrative/UserView'));
const ApprovalHierarchyView = lazy(() => import('../views/Administrative/ApprovalHierarchyView'));
const DiscountView = lazy(() => import('../views/Administrative/DiscountView'));
const RewardView = lazy(() => import('../views/Administrative/RewardView'));
const SalesPerson = lazy(() => import('../views/Administrative/SalesPerson'));
const OrderView = lazy(() => import('../views/OrderView'));
const RedeploymentView = lazy(() => import('../views/Administrative/RedeploymentView'));
const LegalMessageView = lazy(() => import('../views/Administrative/LegalMessage'));

//Commercial Routes
const RepresentativeView = lazy(() => import('../views/Comercial/RepresentativeView'));
const CommercialOrderView = lazy(() => import('../views/Comercial/OrderView'));
const SuspendedCustomersView = lazy(() => import('../views/Comercial/SuspendedCustomersView'));

//Report Routes
const CustomerView = lazy(() => import('../views/Report/CustomerView'));
const ReportOrderView = lazy(() => import('../views/Report/OrderView'));
const ReportProduct = lazy(() => import('../views/Report/ProductList'));

const HomeView = lazy(() => import('../views/Home'));
const Preferences = lazy(() => import('../views/Preferences'));
const Support = lazy(() => import('../views/Support'));

// ** Add the new Views of the project in this route file, and in the file "menu.json" ** //

export default () => {
  const { username, permissions } = useStore(store => store.permission);
  const changePassword = useStore(store => store.app.changePassword);

  return (
    <Workspace menu={addMenuTopAreaItem(menuData, { label: username || '', icon: 'profile-gender_neutral_user' })}>
      <Suspense fallback={null}>
        <Switch>
          <Redirect path="/" exact to="/home" />
          <Route path="/home" component={HomeView} />
          <Route path="/profile" component={Preferences} />
          <Route path="/support" component={Support} />
          <Route path="/commercial/approvalHierarchyView" component={ApprovalHierarchyView} />
          <Route path="/commercial/ordersView" component={CommercialOrderView} />
          <Route path="/report/orders" component={ReportOrderView} />
          <Route path="/report/products" component={ReportProduct} />
          <Route path="/report/customer" component={CustomerView} />
          <Route path="/order/:id" component={OrderView} />
          <Route path="/commercial/legalMessage" component={LegalMessageView} />
          <Route path="/commercial/redeploymentView" component={RedeploymentView} />
          <Route path="/commercial/suspendedCustomersView" component={SuspendedCustomersView} />

          {permissions.includes('app.userType.administrator') && (
            <>
              <Route path="/administrative/userView" component={UserView} />
              <Route path="/administrative/representativeView" component={RepresentativeView} />
              <Route path="/administrative/discountView" component={DiscountView} />
              <Route path="/administrative/salesPerson" component={SalesPerson} />
              <Route path="/administrative/rewardView" component={RewardView} />
            </>
          )}
        </Switch>
      </Suspense>
      <ChangePass isVisible={changePassword} />
    </Workspace>
  );
};
