/**
 * Define your [key, value] messages translations.
 */
export default {
  'app.format.date': 'YYYY-MM-DD',

  'app.format.dateTime': 'YYYY-MM-DD HH:mm',

  'app.changePass.title': 'Change Password!',
  'app.changePass.send': 'Send',

  'app.menu.administrative': 'Administrativo',
  'app.menu.user': 'Cadastro Usuário',
};
