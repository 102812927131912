import { notification } from '@digital/ui';
import { ListProps } from 'src/types/customTypes';
import { apiClient } from '../apiClient';

export interface ShowUsersProps {
  UserCode: string;
  Name: string;
  Email: string;
  Active: boolean;
  CreatedAt: string;
  PasswordUpdatedAt: string | null;
  SecurityGroupId: number | null;
  EstablishmentId: number;
  RepresentativeId: number;
  OldPassword?: string;
  Password?: string;
  ConfirmPassword?: string;
}

export interface LostPassProps {
  email: string;
}

export interface PassChangeProps {
  password: string;
  confirmPassword: string;
}

export interface SecurityGroupProps {
  SecurityGroupId: number;
  Name: string;
}

export interface EstablishmentProps {
  Active: boolean;
  Description: string;
  EstablishmentId: number;
  Name: string;
}

export const onAllUser = () => apiClient.get<ShowUsersProps[]>('/account/user');

export const onUser = (UserCode: string) => apiClient.get<ShowUsersProps>(`/account/user/${UserCode}`);

export const getOnUser = (item: ShowUsersProps) => apiClient.get<ShowUsersProps>(`/account/user/${item.UserCode}`);

export const onUserCreate = (item: ShowUsersProps) =>
  apiClient.post<ShowUsersProps>(`/account/user`, {
    userCode: item.UserCode,
    name: item.Name,
    email: item.Email,
    password: item.Password,
    establishmentId: item.EstablishmentId,
    confirmPassword: item.ConfirmPassword,
    securityGroup: item.SecurityGroupId,
    representativeId: item.RepresentativeId
  });

export const onUserUpdate = (item: ShowUsersProps) =>
  apiClient.put<boolean>(`/account/user/${item.UserCode}`, {
    userCode: item.UserCode,
    name: item.Name,
    email: item.Email,
    active: item.Active,
    password: item.Password,
    confirmPassword: item.ConfirmPassword,
    securityGroup: item.SecurityGroupId,
    establishmentId: item.EstablishmentId,
    representativeId: item.RepresentativeId,
    formType: 'admin'
  }).then(resp => resp !== undefined ? resp : false);

export const onUserUpdatePreferences = (item: ShowUsersProps) =>
  apiClient.put<ShowUsersProps>(`/account/user/${item.UserCode}`, {
    name: item.Name,
    email: item.Email,
    password: item.Password,
    confirmPassword: item.ConfirmPassword,
    oldPassword: item.OldPassword,
    formType: 'profile'
  }).then(resp => {
    if (resp !== undefined) {
      notification.success("DADOS_ATUALIZADOS_SUCESSO");
      return resp as ShowUsersProps
    }
    return false
  });

export const onDisableUser = (id: string) => apiClient.get<{ msg: string }>(`/account/user/status/${id}`).then(resp => {
  if (resp !== undefined) {
    notification.success(resp.msg);
    return true
  }
  return false
});

export const onLostPass = (values: LostPassProps) =>
  apiClient.post<{ msg: string }>('/system/mailService/forgotPassword', values);

export const onPassChange = (values: PassChangeProps) => apiClient.post('/account/user/password/update', values);

export const onSecurityGroups = () => apiClient.get<SecurityGroupProps[]>('/account/groups');

export const onEstablishment = () => apiClient.get<EstablishmentProps[]>('/account/establishment');

export const onUsersApprovalHierarchy = () => apiClient.getNoLoad<ListProps[]>('/account/user/combo');
