import storage from '../../helpers/storage';
import { apiClient } from '../apiClient';

export interface LoginProps {
  user: string;
  password: string;
}

interface ResponseAccount {
  user: {
    EstablishmentId: number;
    SecurityGroupId: number;
    RepresentativeId: number;
    UserCode: "admin"
    UserId: number;
    Email: string;
    Name: string;
    CreatedAt: string;
    PasswordUpdatedAt: string | null;
  };
  permission: string;
}

interface ResponseLogin extends ResponseAccount {
  tokenAccess: string;
}

export const onLoginUser = (values: LoginProps) => apiClient.post<ResponseLogin>('/account/login', values);

export const onAuth = () => apiClient.get<ResponseAccount>('/account/auth');

export const onRefreshToken = () => {
  return apiClient.get<ResponseLogin>('/account/refreshToken').then(resp => {
    if (resp) {
      localStorage.setItem('token-sales_portal', resp.tokenAccess);
      storage.setApiToken(resp.tokenAccess);
      return resp;
    }
    return undefined
  })
};

export const onLogout = () => apiClient.getNoLoad('/account/logout');
