/**
 * Empty application store example.
 * Refer to https://easy-peasy.now.sh/ for more information
 */
import { Action, action } from 'easy-peasy';

interface UserDataProps {
  Name: string;
  Email: string;
}

interface SaveOrderProps {
  saved: boolean;
  item: string;
}
export interface AppModel {
  userData: UserDataProps | null;
  refreshLogin: boolean;
  changePassword: boolean;
  onRefreshLogin: Action<AppModel, boolean>;
  onChangePassword: Action<AppModel, boolean>;
  establishmentId: number;
  onEstablishmentId: Action<AppModel, number>;
  userCode: string | null;
  onUserCode: Action<AppModel, string>;
  onUserData: Action<AppModel, UserDataProps>;
  representativeId: number | null;
  onRepresentativeId: Action<AppModel, number>;
  userToken: string | null;
  onUserToken: Action<AppModel, string>;
  saveOrder: {
    saved: boolean;
    item: string;
  };
  onSaveOrder: Action<AppModel, SaveOrderProps>;
  orderPage: boolean;
  onOrderPage: Action<AppModel, boolean>;
}

const app: AppModel = {
  userData: null,
  refreshLogin: false,
  changePassword: false,
  onRefreshLogin: action((state, payload) => {
    state.refreshLogin = payload;
  }),
  onChangePassword: action((state, payload) => {
    state.changePassword = payload;
  }),
  establishmentId: 0,
  onEstablishmentId: action((state, payload) => {
    state.establishmentId = payload;
  }),
  userCode: null,
  onUserCode: action((state, payload) => {
    state.userCode = payload;
  }),
  onUserData: action((state, payload) => {
    state.userData = {
      Name: payload.Name,
      Email: payload.Email
    }
  }),
  representativeId: null,
  onRepresentativeId: action((state, payload) => {
    state.representativeId = payload;
  }),
  userToken: null,
  onUserToken: action((state, payload) => {
    state.userToken = payload;
  }),
  saveOrder: {
    saved: true,
    item: ''
  },
  onSaveOrder: action((state, payload) => {
    state.saveOrder = payload;
  }),
  orderPage: false,
  onOrderPage: action((state, payload) => {
    state.orderPage = payload;
  }),
};

export default app;
