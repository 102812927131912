import MockAdapter from 'axios-mock-adapter';
import faker from 'faker';

/**
 * Create mocked Equipment list
 */
const LegalMessage = Array(2)
  .fill(0)
  .map((v, index) => ({
    MessageCode: index,
    Message: faker.random.words(23),
    ClientId: faker.random.number({ min: 1, max: 2 }),
    Client: faker.commerce.productName(),
    Active: faker.random.boolean()
  }));

/**
 * Mock equipmentApi endpoints
 */
export default (mock: MockAdapter) => {
  mock.onGet('/portal/legal-message').reply(200, LegalMessage);
};

// messageCode: item.MessageCode,
// message: item.Message,
// ClientId: item.ClientId,
// client: item.Client
